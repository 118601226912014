import {
  ICreateRemoveDirectoryData,
  ICreateVersionData,
  IGetFileData,
  IMoveDirectoryData,
  IMoveFileData,
  IRemoveFileData,
  IRenameRepositoryData,
  IRemoveRepositoryVersion,
  IRenameDirectoryData,
  IRenameFileData,
  ITutorialStatus,
  IDownloadFileData,
  IUploadRepositoryFileParams,
  IUploadUpcomingFileParams,
} from 'types';
import Base, { clearToken } from './Base';
import { IAddMemberParams, INotificationEmailParams, IUpdateMemberRolesParams } from 'types/user';

class Users extends Base {
  clearToken = clearToken;
  getAuth = () => this.apiGet('/user/auth');
  getSignedUrls = (fileNames: string[]) => this.apiPost('/repositories/generate-signed-urls', { fileNames });
  updateUserProfile = (data: FormData) => this.apiPut('/user/profile', data);
  searchUser = (keyword: string) => this.apiGet('/user/search', { keyword });
  notificationEmailSetting = (repositoryId: string, data: INotificationEmailParams) =>
    this.apiPut(`/repositories/${repositoryId}/members/save-notification-emails`, data);
  addMember = (repositoryId: string, body: IAddMemberParams) =>
    this.apiPost(`/repositories/${repositoryId}/members`, body);
  updateMemberRoles = (repositoryId: string, body: IUpdateMemberRolesParams) =>
    this.apiPut(`/repositories/${repositoryId}/members/roles`, body);
  getRepositories = (body?: any) => this.apiGet('/repositories', body);
  createRepository = (body: any) => this.apiPost('/repositories/shared', body);
  getRepositoryDetail = (repositoryId: string) => this.apiGet(`/repositories/${repositoryId}`);
  addRepositoryFile = (repositoryId: string, params: IUploadRepositoryFileParams) => this.apiPost(`/repositories/${repositoryId}/files`, params);
  addRepositoryDirectory = (repositoryId: string, body: ICreateRemoveDirectoryData) =>
    this.apiPost(`/repositories/${repositoryId}/directories`, body);
  removeRepositoryFile = (repositoryId: string, data: IRemoveFileData) =>
    this.apiDelete(`/repositories/${repositoryId}/files`, data);
  removeRepositoryDirectory = (repositoryId: string, data: ICreateRemoveDirectoryData) =>
    this.apiDelete(`/repositories/${repositoryId}/directories`, data);
  updateRepositoryGeneral = (repositoryId: string, data: IRenameRepositoryData) =>
    this.apiPut(`/repositories/${repositoryId}/rename`, data);
  renameRepositoryFile = (repositoryId: string, data: IRenameFileData) =>
    this.apiPut(`/repositories/${repositoryId}/files/rename`, data);
  renameRepositoryDirectory = (repositoryId: string, data: IRenameDirectoryData) =>
    this.apiPut(`/repositories/${repositoryId}/directories/rename`, data);
  updateRepositoryFile = (repositoryId: string, params: IUploadUpcomingFileParams) => this.apiPost(`/repositories/${repositoryId}/files/upcoming`, params);
  createRepositoryVersion = (repositoryId: string, body: ICreateVersionData) =>
    this.apiPost(`/repositories/${repositoryId}/versions`, body);
  removeRepositoryVersion = (repositoryId: string, body: IRemoveRepositoryVersion) =>
    this.apiDelete(`/repositories/${repositoryId}/versions`, body);
  updateRepositoryTutorialsStatus = (repositoryId: string, body: ITutorialStatus) =>
    this.apiPut(`/repositories/${repositoryId}/tutorial-status`, body);
  moveRepositoryFile = (repositoryId: string, data: IMoveFileData) =>
    this.apiPut(`/repositories/${repositoryId}/move-file`, data);
  moveRepositoryDirectory = (repositoryId: string, data: IMoveDirectoryData) =>
    this.apiPut(`/repositories/${repositoryId}/move-directory`, data);
  updateApplyUpcoming = (repositoryId: string | undefined, data: any) =>
    this.apiPut(`/repositories/${repositoryId}/files/apply-upcoming`, data);
  updateApplyCurrent = (repositoryId: string | undefined, data: any) =>
    this.apiPut(`/repositories/${repositoryId}/files/apply-current`, data);
  getRepositoryTickets = (repositoryId: string) => this.apiGet(`/repositories/${repositoryId}/tickets`);
  getRepositoryFile = (repositoryId: string, data: IGetFileData) =>
    this.apiGet(`/repositories/${repositoryId}/file`, data);
  downloadRepositoryFiles = (repositoryId: string, data: IDownloadFileData) => {
    return this.apiPost(`/repositories/${repositoryId}/zip-download`, data);
  };
  getTicketsChats = (repositoryId: string, ticketId: string) =>
    this.apiGet(`/repositories/${repositoryId}/tickets/${ticketId}/chats`);
  createTicketMessage = (repositoryId: string, ticketId: string, data: FormData) => {
    return this.apiPost(`/repositories/${repositoryId}/tickets/${ticketId}/chats`, data);
  };
}
const usersInstance = new Users();

export default usersInstance;
